<template>
  <div>      
      
      <!-- Top Banner -->

      <section class="container" id="banner">
        <div class="container pt-4">
          <h2 class=" mb-4"> 
              Get winning Today! 
          </h2> 
    
          <p class="mb-4"> 
              Win goodies from electronics to food!
          </p> 
    
          <a class="btn btn-custom" style="background-color: #fff; color: #222222;"> 
              Enter Now! 
          </a> 
        </div>
      </section>

      <!-- Active Competitions -->

      <section class="container">
            <h1 class="text-center mt-5 mb-5">Active Competitions</h1>
            <div class="row">
                <div class="col" onclick="location.href='/competition.html'">
                    <div class="competition-item">
                        <img src="https://media.geeksforgeeks.org/wp-content/uploads/20200914000601/gfg-300x200.jpg" alt=""/>
                    </div>
                    <div class="details">
                        <div class="row">
                            <div class="col">
                                <!-- Timer -->
                                <div class="row">
                                      <div class="col">
                                        <div class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                <circle id="Ellipse_7" data-name="Ellipse 7" cx="22" cy="22" r="22" fill="#fff"/>
                                                <g id="Icon_ionic-md-time-2" data-name="Icon ionic-md-time" transform="translate(2.625 2.625)">
                                                  <path id="Path_5-2" data-name="Path 5" d="M19.5,3.375A16.142,16.142,0,1,0,35.659,19.517,16.136,16.136,0,0,0,19.5,3.375Zm.016,29.055A12.913,12.913,0,1,1,32.43,19.517,12.913,12.913,0,0,1,19.517,32.43Z" fill="#4fd1c1"/>
                                                  <path id="Path_6-2" data-name="Path 6" d="M18.959,10.687H16.538v9.685l8.474,5.083,1.211-1.987-7.264-4.307Z" transform="translate(1.365 0.758)" fill="#4fd1c1"/>
                                                </g>
                                              </svg>    
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <div id="clockdiv">
                                          <ul class="time">
                                            <li class="days"></li>
                                            <li class="hours"></li>
                                            <li class="minutes"></li>
                                            <li class="seconds"></li>
                                          </ul>
                                          <ul class="labels">
                                            <li class="smalltext">DAYS</li>
                                            <li class="smalltext">HRS</li>
                                            <li class="smalltext">MIN</li>
                                            <li class="smalltext">SEC</li>
                                          </ul>
                                        </div>
                                      </div>
                                </div>
                            </div>
                            <div class="col">
                                <!-- Amount of tickets -->
                                <div class="">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                    <circle id="Ellipse_5" data-name="Ellipse 5" cx="22" cy="22" r="22" fill="#fff"/>
                                                    <path id="Icon_awesome-ticket-alt-16" data-name="Icon awesome-ticket-alt" d="M6.17,9.128H21.6v9.256H6.17Zm19.283,4.628a2.314,2.314,0,0,0,2.314,2.314V20.7a2.314,2.314,0,0,1-2.314,2.314H2.314A2.314,2.314,0,0,1,0,20.7V16.07a2.314,2.314,0,0,0,2.314-2.314A2.314,2.314,0,0,0,0,11.442V6.814A2.314,2.314,0,0,1,2.314,4.5H25.453a2.314,2.314,0,0,1,2.314,2.314v4.628A2.314,2.314,0,0,0,25.453,13.756ZM23.139,8.742a1.157,1.157,0,0,0-1.157-1.157H5.785A1.157,1.157,0,0,0,4.628,8.742V18.769a1.157,1.157,0,0,0,1.157,1.157h16.2a1.157,1.157,0,0,0,1.157-1.157Z" transform="translate(8 7.5)" fill="#4fd1c1"/>
                                                  </svg>                                       
                                            </div>
                                        </div>

                                        <div class="col-9">
                                            <span class="tickets">30 / 100</span>   
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col">
                                <!-- Price -->
                                <div class="row">
                                    <div class="col">
                                            <div class="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                    <circle id="Ellipse_6" data-name="Ellipse 6" cx="22" cy="22" r="22" fill="#fff"/>
                                                    <path id="Icon_metro-gbp" data-name="Icon metro-gbp" d="M23.758,18.825v5.244a.44.44,0,0,1-.457.457H9.64a.44.44,0,0,1-.457-.457V21.925a.464.464,0,0,1,.457-.457h1.386V16H9.669a.433.433,0,0,1-.329-.136.452.452,0,0,1-.129-.322V13.666a.44.44,0,0,1,.457-.457h1.357V10.023a5.18,5.18,0,0,1,1.765-4.03,6.468,6.468,0,0,1,4.494-1.586,7.291,7.291,0,0,1,4.787,1.786.422.422,0,0,1,.143.293.442.442,0,0,1-.1.322L20.643,8.622a.438.438,0,0,1-.314.171.383.383,0,0,1-.329-.1,3.14,3.14,0,0,0-.372-.271,5.2,5.2,0,0,0-.986-.457,3.775,3.775,0,0,0-1.329-.257,2.808,2.808,0,0,0-1.958.672,2.257,2.257,0,0,0-.743,1.758v3.072h4.358a.452.452,0,0,1,.321.129.433.433,0,0,1,.136.329v1.872a.464.464,0,0,1-.457.457H14.613v5.416h5.916V18.825a.452.452,0,0,1,.129-.321.433.433,0,0,1,.329-.136H23.3a.433.433,0,0,1,.329.136.452.452,0,0,1,.129.321Z" transform="translate(5.957 6.593)" fill="#4fd1c1"/>
                                                </svg>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <span class="price">£3.50</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                  <div class="competition-item">
                      <img src="https://media.geeksforgeeks.org/wp-content/uploads/20200914000601/gfg-300x200.jpg" alt=""/>
                  </div>
                  <div class="details">
                      <div class="row">
                          <div class="col">
                              <!-- Timer -->
                              <div class="row">
                                    <div class="col">
                                      <div class="icon">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                              <circle id="Ellipse_7" data-name="Ellipse 7" cx="22" cy="22" r="22" fill="#fff"/>
                                              <g id="Icon_ionic-md-time-2" data-name="Icon ionic-md-time" transform="translate(2.625 2.625)">
                                                <path id="Path_5-2" data-name="Path 5" d="M19.5,3.375A16.142,16.142,0,1,0,35.659,19.517,16.136,16.136,0,0,0,19.5,3.375Zm.016,29.055A12.913,12.913,0,1,1,32.43,19.517,12.913,12.913,0,0,1,19.517,32.43Z" fill="#4fd1c1"/>
                                                <path id="Path_6-2" data-name="Path 6" d="M18.959,10.687H16.538v9.685l8.474,5.083,1.211-1.987-7.264-4.307Z" transform="translate(1.365 0.758)" fill="#4fd1c1"/>
                                              </g>
                                            </svg>    
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div id="clockdiv">
                                        <ul class="time">
                                          <li class="days"></li>
                                          <li class="hours"></li>
                                          <li class="minutes"></li>
                                          <li class="seconds"></li>
                                        </ul>
                                        <ul class="labels">
                                          <li class="smalltext">DAYS</li>
                                          <li class="smalltext">HRS</li>
                                          <li class="smalltext">MIN</li>
                                          <li class="smalltext">SEC</li>
                                        </ul>
                                      </div>
                                    </div>
                              </div>
                          </div>
                          <div class="col">
                              <!-- Amount of tickets -->
                              <div class="">
                                  <div class="row">
                                      <div class="col-1">
                                          <div class="icon">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                  <circle id="Ellipse_5" data-name="Ellipse 5" cx="22" cy="22" r="22" fill="#fff"/>
                                                  <path id="Icon_awesome-ticket-alt-16" data-name="Icon awesome-ticket-alt" d="M6.17,9.128H21.6v9.256H6.17Zm19.283,4.628a2.314,2.314,0,0,0,2.314,2.314V20.7a2.314,2.314,0,0,1-2.314,2.314H2.314A2.314,2.314,0,0,1,0,20.7V16.07a2.314,2.314,0,0,0,2.314-2.314A2.314,2.314,0,0,0,0,11.442V6.814A2.314,2.314,0,0,1,2.314,4.5H25.453a2.314,2.314,0,0,1,2.314,2.314v4.628A2.314,2.314,0,0,0,25.453,13.756ZM23.139,8.742a1.157,1.157,0,0,0-1.157-1.157H5.785A1.157,1.157,0,0,0,4.628,8.742V18.769a1.157,1.157,0,0,0,1.157,1.157h16.2a1.157,1.157,0,0,0,1.157-1.157Z" transform="translate(8 7.5)" fill="#4fd1c1"/>
                                                </svg>                                       
                                          </div>
                                      </div>

                                      <div class="col-9">
                                          <span class="tickets">30 / 100</span>   
                                      </div>
                                  </div>

                              </div>
                          </div>
                          <div class="col">
                              <!-- Price -->
                              <div class="row">
                                  <div class="col">
                                          <div class="icon">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                  <circle id="Ellipse_6" data-name="Ellipse 6" cx="22" cy="22" r="22" fill="#fff"/>
                                                  <path id="Icon_metro-gbp" data-name="Icon metro-gbp" d="M23.758,18.825v5.244a.44.44,0,0,1-.457.457H9.64a.44.44,0,0,1-.457-.457V21.925a.464.464,0,0,1,.457-.457h1.386V16H9.669a.433.433,0,0,1-.329-.136.452.452,0,0,1-.129-.322V13.666a.44.44,0,0,1,.457-.457h1.357V10.023a5.18,5.18,0,0,1,1.765-4.03,6.468,6.468,0,0,1,4.494-1.586,7.291,7.291,0,0,1,4.787,1.786.422.422,0,0,1,.143.293.442.442,0,0,1-.1.322L20.643,8.622a.438.438,0,0,1-.314.171.383.383,0,0,1-.329-.1,3.14,3.14,0,0,0-.372-.271,5.2,5.2,0,0,0-.986-.457,3.775,3.775,0,0,0-1.329-.257,2.808,2.808,0,0,0-1.958.672,2.257,2.257,0,0,0-.743,1.758v3.072h4.358a.452.452,0,0,1,.321.129.433.433,0,0,1,.136.329v1.872a.464.464,0,0,1-.457.457H14.613v5.416h5.916V18.825a.452.452,0,0,1,.129-.321.433.433,0,0,1,.329-.136H23.3a.433.433,0,0,1,.329.136.452.452,0,0,1,.129.321Z" transform="translate(5.957 6.593)" fill="#4fd1c1"/>
                                              </svg>
                                      </div>
                                  </div>
                                  <div class="col">
                                      <span class="price">£3.50</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col">
                <div class="competition-item">
                    <img src="https://media.geeksforgeeks.org/wp-content/uploads/20200914000601/gfg-300x200.jpg" alt=""/>
                </div>
                <div class="details">
                    <div class="row">
                        <div class="col">
                            <!-- Timer -->
                            <div class="row">
                                  <div class="col">
                                    <div class="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                            <circle id="Ellipse_7" data-name="Ellipse 7" cx="22" cy="22" r="22" fill="#fff"/>
                                            <g id="Icon_ionic-md-time-2" data-name="Icon ionic-md-time" transform="translate(2.625 2.625)">
                                              <path id="Path_5-2" data-name="Path 5" d="M19.5,3.375A16.142,16.142,0,1,0,35.659,19.517,16.136,16.136,0,0,0,19.5,3.375Zm.016,29.055A12.913,12.913,0,1,1,32.43,19.517,12.913,12.913,0,0,1,19.517,32.43Z" fill="#4fd1c1"/>
                                              <path id="Path_6-2" data-name="Path 6" d="M18.959,10.687H16.538v9.685l8.474,5.083,1.211-1.987-7.264-4.307Z" transform="translate(1.365 0.758)" fill="#4fd1c1"/>
                                            </g>
                                          </svg>    
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div id="clockdiv">
                                      <ul class="time">
                                        <li class="days"></li>
                                        <li class="hours"></li>
                                        <li class="minutes"></li>
                                        <li class="seconds"></li>
                                      </ul>
                                      <ul class="labels">
                                        <li class="smalltext">DAYS</li>
                                        <li class="smalltext">HRS</li>
                                        <li class="smalltext">MIN</li>
                                        <li class="smalltext">SEC</li>
                                      </ul>
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col">
                            <!-- Amount of tickets -->
                            <div class="">
                                <div class="row">
                                    <div class="col-1">
                                        <div class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                <circle id="Ellipse_5" data-name="Ellipse 5" cx="22" cy="22" r="22" fill="#fff"/>
                                                <path id="Icon_awesome-ticket-alt-16" data-name="Icon awesome-ticket-alt" d="M6.17,9.128H21.6v9.256H6.17Zm19.283,4.628a2.314,2.314,0,0,0,2.314,2.314V20.7a2.314,2.314,0,0,1-2.314,2.314H2.314A2.314,2.314,0,0,1,0,20.7V16.07a2.314,2.314,0,0,0,2.314-2.314A2.314,2.314,0,0,0,0,11.442V6.814A2.314,2.314,0,0,1,2.314,4.5H25.453a2.314,2.314,0,0,1,2.314,2.314v4.628A2.314,2.314,0,0,0,25.453,13.756ZM23.139,8.742a1.157,1.157,0,0,0-1.157-1.157H5.785A1.157,1.157,0,0,0,4.628,8.742V18.769a1.157,1.157,0,0,0,1.157,1.157h16.2a1.157,1.157,0,0,0,1.157-1.157Z" transform="translate(8 7.5)" fill="#4fd1c1"/>
                                              </svg>                                       
                                        </div>
                                    </div>

                                    <div class="col-9">
                                        <span class="tickets">30 / 100</span>   
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col">
                            <!-- Price -->
                            <div class="row">
                                <div class="col">
                                        <div class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                <circle id="Ellipse_6" data-name="Ellipse 6" cx="22" cy="22" r="22" fill="#fff"/>
                                                <path id="Icon_metro-gbp" data-name="Icon metro-gbp" d="M23.758,18.825v5.244a.44.44,0,0,1-.457.457H9.64a.44.44,0,0,1-.457-.457V21.925a.464.464,0,0,1,.457-.457h1.386V16H9.669a.433.433,0,0,1-.329-.136.452.452,0,0,1-.129-.322V13.666a.44.44,0,0,1,.457-.457h1.357V10.023a5.18,5.18,0,0,1,1.765-4.03,6.468,6.468,0,0,1,4.494-1.586,7.291,7.291,0,0,1,4.787,1.786.422.422,0,0,1,.143.293.442.442,0,0,1-.1.322L20.643,8.622a.438.438,0,0,1-.314.171.383.383,0,0,1-.329-.1,3.14,3.14,0,0,0-.372-.271,5.2,5.2,0,0,0-.986-.457,3.775,3.775,0,0,0-1.329-.257,2.808,2.808,0,0,0-1.958.672,2.257,2.257,0,0,0-.743,1.758v3.072h4.358a.452.452,0,0,1,.321.129.433.433,0,0,1,.136.329v1.872a.464.464,0,0,1-.457.457H14.613v5.416h5.916V18.825a.452.452,0,0,1,.129-.321.433.433,0,0,1,.329-.136H23.3a.433.433,0,0,1,.329.136.452.452,0,0,1,.129.321Z" transform="translate(5.957 6.593)" fill="#4fd1c1"/>
                                            </svg>
                                    </div>
                                </div>
                                <div class="col">
                                    <span class="price">£3.50</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
      </section>

      <section class="container">
        <div class="row">
            <div class="col">
                <div class="competition-item">
                    <img src="https://media.geeksforgeeks.org/wp-content/uploads/20200914000601/gfg-300x200.jpg" alt=""/>
                </div>
                <div class="details">
                    <div class="row">
                        <div class="col">
                            <!-- Timer -->
                            <div class="row">
                                  <div class="col">
                                    <div class="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                            <circle id="Ellipse_7" data-name="Ellipse 7" cx="22" cy="22" r="22" fill="#fff"/>
                                            <g id="Icon_ionic-md-time-2" data-name="Icon ionic-md-time" transform="translate(2.625 2.625)">
                                              <path id="Path_5-2" data-name="Path 5" d="M19.5,3.375A16.142,16.142,0,1,0,35.659,19.517,16.136,16.136,0,0,0,19.5,3.375Zm.016,29.055A12.913,12.913,0,1,1,32.43,19.517,12.913,12.913,0,0,1,19.517,32.43Z" fill="#4fd1c1"/>
                                              <path id="Path_6-2" data-name="Path 6" d="M18.959,10.687H16.538v9.685l8.474,5.083,1.211-1.987-7.264-4.307Z" transform="translate(1.365 0.758)" fill="#4fd1c1"/>
                                            </g>
                                          </svg>    
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div id="clockdiv">
                                      <ul class="time">
                                        <li class="days"></li>
                                        <li class="hours"></li>
                                        <li class="minutes"></li>
                                        <li class="seconds"></li>
                                      </ul>
                                      <ul class="labels">
                                        <li class="smalltext">DAYS</li>
                                        <li class="smalltext">HRS</li>
                                        <li class="smalltext">MIN</li>
                                        <li class="smalltext">SEC</li>
                                      </ul>
                                    </div>
                                  </div>
                            </div>
                        </div>
                        <div class="col">
                            <!-- Amount of tickets -->
                            <div class="">
                                <div class="row">
                                    <div class="col-1">
                                        <div class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                <circle id="Ellipse_5" data-name="Ellipse 5" cx="22" cy="22" r="22" fill="#fff"/>
                                                <path id="Icon_awesome-ticket-alt-16" data-name="Icon awesome-ticket-alt" d="M6.17,9.128H21.6v9.256H6.17Zm19.283,4.628a2.314,2.314,0,0,0,2.314,2.314V20.7a2.314,2.314,0,0,1-2.314,2.314H2.314A2.314,2.314,0,0,1,0,20.7V16.07a2.314,2.314,0,0,0,2.314-2.314A2.314,2.314,0,0,0,0,11.442V6.814A2.314,2.314,0,0,1,2.314,4.5H25.453a2.314,2.314,0,0,1,2.314,2.314v4.628A2.314,2.314,0,0,0,25.453,13.756ZM23.139,8.742a1.157,1.157,0,0,0-1.157-1.157H5.785A1.157,1.157,0,0,0,4.628,8.742V18.769a1.157,1.157,0,0,0,1.157,1.157h16.2a1.157,1.157,0,0,0,1.157-1.157Z" transform="translate(8 7.5)" fill="#4fd1c1"/>
                                              </svg>                                       
                                        </div>
                                    </div>

                                    <div class="col-9">
                                        <span class="tickets">30 / 100</span>   
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col">
                            <!-- Price -->
                            <div class="row">
                                <div class="col">
                                        <div class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                <circle id="Ellipse_6" data-name="Ellipse 6" cx="22" cy="22" r="22" fill="#fff"/>
                                                <path id="Icon_metro-gbp" data-name="Icon metro-gbp" d="M23.758,18.825v5.244a.44.44,0,0,1-.457.457H9.64a.44.44,0,0,1-.457-.457V21.925a.464.464,0,0,1,.457-.457h1.386V16H9.669a.433.433,0,0,1-.329-.136.452.452,0,0,1-.129-.322V13.666a.44.44,0,0,1,.457-.457h1.357V10.023a5.18,5.18,0,0,1,1.765-4.03,6.468,6.468,0,0,1,4.494-1.586,7.291,7.291,0,0,1,4.787,1.786.422.422,0,0,1,.143.293.442.442,0,0,1-.1.322L20.643,8.622a.438.438,0,0,1-.314.171.383.383,0,0,1-.329-.1,3.14,3.14,0,0,0-.372-.271,5.2,5.2,0,0,0-.986-.457,3.775,3.775,0,0,0-1.329-.257,2.808,2.808,0,0,0-1.958.672,2.257,2.257,0,0,0-.743,1.758v3.072h4.358a.452.452,0,0,1,.321.129.433.433,0,0,1,.136.329v1.872a.464.464,0,0,1-.457.457H14.613v5.416h5.916V18.825a.452.452,0,0,1,.129-.321.433.433,0,0,1,.329-.136H23.3a.433.433,0,0,1,.329.136.452.452,0,0,1,.129.321Z" transform="translate(5.957 6.593)" fill="#4fd1c1"/>
                                            </svg>
                                    </div>
                                </div>
                                <div class="col">
                                    <span class="price">£3.50</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
              <div class="competition-item">
                  <img src="https://media.geeksforgeeks.org/wp-content/uploads/20200914000601/gfg-300x200.jpg" alt=""/>
              </div>
              <div class="details">
                  <div class="row">
                      <div class="col">
                          <!-- Timer -->
                          <div class="row">
                                <div class="col">
                                  <div class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                          <circle id="Ellipse_7" data-name="Ellipse 7" cx="22" cy="22" r="22" fill="#fff"/>
                                          <g id="Icon_ionic-md-time-2" data-name="Icon ionic-md-time" transform="translate(2.625 2.625)">
                                            <path id="Path_5-2" data-name="Path 5" d="M19.5,3.375A16.142,16.142,0,1,0,35.659,19.517,16.136,16.136,0,0,0,19.5,3.375Zm.016,29.055A12.913,12.913,0,1,1,32.43,19.517,12.913,12.913,0,0,1,19.517,32.43Z" fill="#4fd1c1"/>
                                            <path id="Path_6-2" data-name="Path 6" d="M18.959,10.687H16.538v9.685l8.474,5.083,1.211-1.987-7.264-4.307Z" transform="translate(1.365 0.758)" fill="#4fd1c1"/>
                                          </g>
                                        </svg>    
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div id="clockdiv">
                                    <ul class="time">
                                      <li class="days"></li>
                                      <li class="hours"></li>
                                      <li class="minutes"></li>
                                      <li class="seconds"></li>
                                    </ul>
                                    <ul class="labels">
                                      <li class="smalltext">DAYS</li>
                                      <li class="smalltext">HRS</li>
                                      <li class="smalltext">MIN</li>
                                      <li class="smalltext">SEC</li>
                                    </ul>
                                  </div>
                                </div>
                          </div>
                      </div>
                      <div class="col">
                          <!-- Amount of tickets -->
                          <div class="">
                              <div class="row">
                                  <div class="col-1">
                                      <div class="icon">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                              <circle id="Ellipse_5" data-name="Ellipse 5" cx="22" cy="22" r="22" fill="#fff"/>
                                              <path id="Icon_awesome-ticket-alt-16" data-name="Icon awesome-ticket-alt" d="M6.17,9.128H21.6v9.256H6.17Zm19.283,4.628a2.314,2.314,0,0,0,2.314,2.314V20.7a2.314,2.314,0,0,1-2.314,2.314H2.314A2.314,2.314,0,0,1,0,20.7V16.07a2.314,2.314,0,0,0,2.314-2.314A2.314,2.314,0,0,0,0,11.442V6.814A2.314,2.314,0,0,1,2.314,4.5H25.453a2.314,2.314,0,0,1,2.314,2.314v4.628A2.314,2.314,0,0,0,25.453,13.756ZM23.139,8.742a1.157,1.157,0,0,0-1.157-1.157H5.785A1.157,1.157,0,0,0,4.628,8.742V18.769a1.157,1.157,0,0,0,1.157,1.157h16.2a1.157,1.157,0,0,0,1.157-1.157Z" transform="translate(8 7.5)" fill="#4fd1c1"/>
                                            </svg>                                       
                                      </div>
                                  </div>

                                  <div class="col-9">
                                      <span class="tickets">30 / 100</span>   
                                  </div>
                              </div>

                          </div>
                      </div>
                      <div class="col">
                          <!-- Price -->
                          <div class="row">
                              <div class="col">
                                      <div class="icon">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                              <circle id="Ellipse_6" data-name="Ellipse 6" cx="22" cy="22" r="22" fill="#fff"/>
                                              <path id="Icon_metro-gbp" data-name="Icon metro-gbp" d="M23.758,18.825v5.244a.44.44,0,0,1-.457.457H9.64a.44.44,0,0,1-.457-.457V21.925a.464.464,0,0,1,.457-.457h1.386V16H9.669a.433.433,0,0,1-.329-.136.452.452,0,0,1-.129-.322V13.666a.44.44,0,0,1,.457-.457h1.357V10.023a5.18,5.18,0,0,1,1.765-4.03,6.468,6.468,0,0,1,4.494-1.586,7.291,7.291,0,0,1,4.787,1.786.422.422,0,0,1,.143.293.442.442,0,0,1-.1.322L20.643,8.622a.438.438,0,0,1-.314.171.383.383,0,0,1-.329-.1,3.14,3.14,0,0,0-.372-.271,5.2,5.2,0,0,0-.986-.457,3.775,3.775,0,0,0-1.329-.257,2.808,2.808,0,0,0-1.958.672,2.257,2.257,0,0,0-.743,1.758v3.072h4.358a.452.452,0,0,1,.321.129.433.433,0,0,1,.136.329v1.872a.464.464,0,0,1-.457.457H14.613v5.416h5.916V18.825a.452.452,0,0,1,.129-.321.433.433,0,0,1,.329-.136H23.3a.433.433,0,0,1,.329.136.452.452,0,0,1,.129.321Z" transform="translate(5.957 6.593)" fill="#4fd1c1"/>
                                          </svg>
                                  </div>
                              </div>
                              <div class="col">
                                  <span class="price">£3.50</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col">
            <div class="competition-item">
                <img src="https://media.geeksforgeeks.org/wp-content/uploads/20200914000601/gfg-300x200.jpg" alt=""/>
            </div>
            <div class="details">
                <div class="row">
                    <div class="col">
                        <!-- Timer -->
                        <div class="row">
                              <div class="col">
                                <div class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <circle id="Ellipse_7" data-name="Ellipse 7" cx="22" cy="22" r="22" fill="#fff"/>
                                        <g id="Icon_ionic-md-time-2" data-name="Icon ionic-md-time" transform="translate(2.625 2.625)">
                                          <path id="Path_5-2" data-name="Path 5" d="M19.5,3.375A16.142,16.142,0,1,0,35.659,19.517,16.136,16.136,0,0,0,19.5,3.375Zm.016,29.055A12.913,12.913,0,1,1,32.43,19.517,12.913,12.913,0,0,1,19.517,32.43Z" fill="#4fd1c1"/>
                                          <path id="Path_6-2" data-name="Path 6" d="M18.959,10.687H16.538v9.685l8.474,5.083,1.211-1.987-7.264-4.307Z" transform="translate(1.365 0.758)" fill="#4fd1c1"/>
                                        </g>
                                      </svg>    
                                </div>
                              </div>
                              <div class="col-12">
                                <div id="clockdiv">
                                  <ul class="time">
                                    <li class="days"></li>
                                    <li class="hours"></li>
                                    <li class="minutes"></li>
                                    <li class="seconds"></li>
                                  </ul>
                                  <ul class="labels">
                                    <li class="smalltext">DAYS</li>
                                    <li class="smalltext">HRS</li>
                                    <li class="smalltext">MIN</li>
                                    <li class="smalltext">SEC</li>
                                  </ul>
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="col">
                        <!-- Amount of tickets -->
                        <div class="">
                            <div class="row">
                                <div class="col-1">
                                    <div class="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                            <circle id="Ellipse_5" data-name="Ellipse 5" cx="22" cy="22" r="22" fill="#fff"/>
                                            <path id="Icon_awesome-ticket-alt-16" data-name="Icon awesome-ticket-alt" d="M6.17,9.128H21.6v9.256H6.17Zm19.283,4.628a2.314,2.314,0,0,0,2.314,2.314V20.7a2.314,2.314,0,0,1-2.314,2.314H2.314A2.314,2.314,0,0,1,0,20.7V16.07a2.314,2.314,0,0,0,2.314-2.314A2.314,2.314,0,0,0,0,11.442V6.814A2.314,2.314,0,0,1,2.314,4.5H25.453a2.314,2.314,0,0,1,2.314,2.314v4.628A2.314,2.314,0,0,0,25.453,13.756ZM23.139,8.742a1.157,1.157,0,0,0-1.157-1.157H5.785A1.157,1.157,0,0,0,4.628,8.742V18.769a1.157,1.157,0,0,0,1.157,1.157h16.2a1.157,1.157,0,0,0,1.157-1.157Z" transform="translate(8 7.5)" fill="#4fd1c1"/>
                                          </svg>                                       
                                    </div>
                                </div>

                                <div class="col-9">
                                    <span class="tickets">30 / 100</span>   
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col">
                        <!-- Price -->
                        <div class="row">
                            <div class="col">
                                    <div class="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                            <circle id="Ellipse_6" data-name="Ellipse 6" cx="22" cy="22" r="22" fill="#fff"/>
                                            <path id="Icon_metro-gbp" data-name="Icon metro-gbp" d="M23.758,18.825v5.244a.44.44,0,0,1-.457.457H9.64a.44.44,0,0,1-.457-.457V21.925a.464.464,0,0,1,.457-.457h1.386V16H9.669a.433.433,0,0,1-.329-.136.452.452,0,0,1-.129-.322V13.666a.44.44,0,0,1,.457-.457h1.357V10.023a5.18,5.18,0,0,1,1.765-4.03,6.468,6.468,0,0,1,4.494-1.586,7.291,7.291,0,0,1,4.787,1.786.422.422,0,0,1,.143.293.442.442,0,0,1-.1.322L20.643,8.622a.438.438,0,0,1-.314.171.383.383,0,0,1-.329-.1,3.14,3.14,0,0,0-.372-.271,5.2,5.2,0,0,0-.986-.457,3.775,3.775,0,0,0-1.329-.257,2.808,2.808,0,0,0-1.958.672,2.257,2.257,0,0,0-.743,1.758v3.072h4.358a.452.452,0,0,1,.321.129.433.433,0,0,1,.136.329v1.872a.464.464,0,0,1-.457.457H14.613v5.416h5.916V18.825a.452.452,0,0,1,.129-.321.433.433,0,0,1,.329-.136H23.3a.433.433,0,0,1,.329.136.452.452,0,0,1,.129.321Z" transform="translate(5.957 6.593)" fill="#4fd1c1"/>
                                        </svg>
                                </div>
                            </div>
                            <div class="col">
                                <span class="price">£3.50</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <button type="button" class="btn btn-custom">Show All</button>
  </section>
  
  <!-- How to enter -->

  <section class="container" id="howto">

    <h1>How to enter</h1>
    <p class="">Three simple steps</p>

    <div class="row">
      <div class="col">
        <svg xmlns="http://www.w3.org/2000/svg" width="237" height="237" viewBox="0 0 237 237">
          <g id="Ellipse_226" data-name="Ellipse 226" fill="#fff" stroke="#fff" stroke-width="1">
            <circle cx="118.5" cy="118.5" r="118.5" stroke="none"/>
            <circle cx="118.5" cy="118.5" r="118" fill="none"/>
          </g>
          <path id="Icon_open-account-login" data-name="Icon open-account-login" d="M25.581,0V8.527H59.69V51.163H25.581V59.69H68.217V0Zm8.527,17.054v8.527H0v8.527H34.108v8.527L51.163,29.845Z" transform="translate(79.1 92.047)" fill="#4fd1c1"/>
        </svg>
        <div class="sub-text">
          1. Sign Up
        </div>
      </div>
      <div class="col">
        <svg xmlns="http://www.w3.org/2000/svg" width="237" height="237" viewBox="0 0 237 237">
          <g id="Ellipse_226" data-name="Ellipse 226" fill="#fff" stroke="#fff" stroke-width="1">
            <circle cx="118.5" cy="118.5" r="118.5" stroke="none"/>
            <circle cx="118.5" cy="118.5" r="118" fill="none"/>
          </g>
          <path id="Icon_open-account-login" data-name="Icon open-account-login" d="M25.581,0V8.527H59.69V51.163H25.581V59.69H68.217V0Zm8.527,17.054v8.527H0v8.527H34.108v8.527L51.163,29.845Z" transform="translate(79.1 92.047)" fill="#4fd1c1"/>
        </svg>
        <div class="sub-text">
          1. Sign Up
        </div>
      </div>
      <div class="col">
        <svg xmlns="http://www.w3.org/2000/svg" width="237" height="237" viewBox="0 0 237 237">
          <g id="Ellipse_226" data-name="Ellipse 226" fill="#fff" stroke="#fff" stroke-width="1">
            <circle cx="118.5" cy="118.5" r="118.5" stroke="none"/>
            <circle cx="118.5" cy="118.5" r="118" fill="none"/>
          </g>
          <path id="Icon_open-account-login" data-name="Icon open-account-login" d="M25.581,0V8.527H59.69V51.163H25.581V59.69H68.217V0Zm8.527,17.054v8.527H0v8.527H34.108v8.527L51.163,29.845Z" transform="translate(79.1 92.047)" fill="#4fd1c1"/>
        </svg>
        <div class="sub-text">
          1. Sign Up
        </div>
      </div>
      <div class="col">
        <svg xmlns="http://www.w3.org/2000/svg" width="237" height="237" viewBox="0 0 237 237">
          <g id="Ellipse_226" data-name="Ellipse 226" fill="#fff" stroke="#fff" stroke-width="1">
            <circle cx="118.5" cy="118.5" r="118.5" stroke="none"/>
            <circle cx="118.5" cy="118.5" r="118" fill="none"/>
          </g>
          <path id="Icon_open-account-login" data-name="Icon open-account-login" d="M25.581,0V8.527H59.69V51.163H25.581V59.69H68.217V0Zm8.527,17.054v8.527H0v8.527H34.108v8.527L51.163,29.845Z" transform="translate(79.1 92.047)" fill="#4fd1c1"/>
        </svg>
        <div class="sub-text">
          1. Sign Up
        </div>
      </div>
    </div>
  </section>


  <Footer/>
  </div>
</template>

<script>
import Footer from '../components/global/Footer'

export default {
    components: {
        Footer
    },
    props: {
      account: Object
    }
}
</script>
